import { render, staticRenderFns } from "./ModalLeaderboard.vue?vue&type=template&id=0a1fe195&scoped=true&"
import script from "./ModalLeaderboard.vue?vue&type=script&lang=js&"
export * from "./ModalLeaderboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a1fe195",
  null
  
)

export default component.exports