<template>
    <section class="pbl container-fluid d-flex flex-column h-100 p-2 py-lg-3 pl-lg-3 pr-lg-3">
        <div class="position-sticky title d-flex align-items-center pb-1">
            <div class="d-flex d-xl-none align-items-center mr-3">
                <button-toggle-side-bar></button-toggle-side-bar>
            </div>
            <h1 class="d-block font-weight-bolder flex-grow-1 text-nowrap pl-xl-0 mb-0">
                Points, Badges, and Leaderboard
            </h1>
            <div class="d-flex align-items-center">

            </div>
        </div>

        <div class="mb-4">
            <div class="position-sticky subheading d-flex justify-content-between pb-2">
                <div class="d-flex align-items-center">
                    <h5 class="mr-2 mb-0">
                        <span class="badge badge-light border">
                            {{ pointSchemes.length }}
                        </span>
                    </h5>
                    <h4 class="mb-0">
                        Point Schemes
                    </h4>
                </div>
                <button class="btn btn-light text-nowrap border rounded-pill"
                        v-on:click="isModalPointSchemeOpen = true">
                    ➕ Scheme
                </button>
            </div>

            <div class="point-schemes row list no-gutters">
                <div class="col-12 empty-notice text-center py-4"
                     v-if="pointSchemes.length === 0">
                    <h1 class="display-3 mb-3">🈳</h1>
                    <div class="d-flex justify-content-center align-items-center flex-wrap">
                        <h5 class="font-weight-normal text-nowrap mb-0">
                            No point scheme.
                        </h5>
                        <button class="btn btn-lg btn-link text-nowrap py-0 pr-1"
                                v-on:click="isModalPointSchemeOpen = true">
                            Create one now?
                        </button>
                    </div>
                </div>
                <point-scheme-card class="col-md-6 col-lg-4 p-2"
                                   v-for="scheme in pointSchemes"
                                   v-bind:key="scheme.id"
                                   v-bind:scheme="scheme">
                </point-scheme-card>
            </div>
        </div>

        <div class="mb-4">
            <div class="position-sticky subheading d-flex justify-content-between pb-2">
                <div class="d-flex align-items-center">
                    <h5 class="mr-2 mb-0">
                        <span class="badge badge-light border">
                            {{ badges.length }}
                        </span>
                    </h5>
                    <h4 class="mb-0">
                        Badges
                    </h4>
                </div>
                <button class="btn btn-light text-nowrap border rounded-pill"
                        v-on:click="isModalBadgeOpen = true">
                    ➕ Badge
                </button>
            </div>

            <div class="point-schemes row list no-gutters">
                <div class="col-12 empty-notice text-center py-4"
                     v-if="badges.length === 0">
                    <h1 class="display-3 mb-3">🈳</h1>
                    <div class="d-flex justify-content-center align-items-center flex-wrap">
                        <h5 class="font-weight-normal text-nowrap mb-0">
                            No badge.
                        </h5>
                        <button class="btn btn-lg btn-link text-nowrap py-0 pr-1"
                                v-on:click="isModalBadgeOpen = true">
                            Create one now?
                        </button>
                    </div>
                </div>
                <badge-card class="col-md-6 col-lg-4 p-2"
                            v-for="badge in badges"
                            v-bind:key="badge.id"
                            v-bind:badge="badge">
                </badge-card>
            </div>
        </div>

        <div class="mb-4">
            <div class="position-sticky subheading d-flex justify-content-between pb-2">
                <div class="d-flex align-items-center">
                    <h5 class="mr-2 mb-0">
                        <span class="badge badge-light border">
                            {{ categories.length }}
                        </span>
                    </h5>
                    <h4 class="mb-0">
                        Categories
                    </h4>
                </div>
                <button class="btn btn-light text-nowrap border rounded-pill"
                        v-on:click="isModalCategoriesOpen = true">
                    ➕ Category
                </button>
            </div>

            <div class="point-schemes row list no-gutters">
                <div class="col-12 empty-notice text-center py-4"
                     v-if="categories.length === 0">
                    <h1 class="display-3 mb-3">🈳</h1>
                    <div class="d-flex justify-content-center align-items-center flex-wrap">
                        <h5 class="font-weight-normal text-nowrap mb-0">
                            No category.
                        </h5>
                        <button class="btn btn-lg btn-link text-nowrap py-0 pr-1"
                                v-on:click="isModalCategoriesOpen = true">
                            Create one now?
                        </button>
                    </div>
                </div>
                <category-card class="col-md-6 col-lg-4 p-2"
                               v-for="category in categories"
                               v-bind:key="category.id"
                               v-bind:category="category">
                </category-card>
            </div>
        </div>

        <modal-badge v-model="isModalBadgeOpen"></modal-badge>
        <modal-point-scheme v-model="isModalPointSchemeOpen"></modal-point-scheme>
        <modal-category v-model="isModalCategoriesOpen"></modal-category>
    </section>
</template>

<script>
import ButtonToggleSideBar from "../components/layout/ButtonToggleSideBar";
import PointSchemeCard from "../components/PBL/PointSchemeCard";
import ModalBadge from "../components/PBL/ModalBadge";
import ModalPointScheme from "../components/PBL/ModalPointScheme";
import ModalCategory from "../components/PBL/ModalCategory";
import CategoryCard from "../components/PBL/CategoryCard";
import BadgeCard from "../components/PBL/BadgeCard";
export default {
    name: "PBL",
    components: {
        BadgeCard,
        CategoryCard,
        ModalCategory,
        ModalPointScheme,
        ModalBadge,
        PointSchemeCard,
        ButtonToggleSideBar,

    },
    metaInfo: {
        title: "PBL",
    },
    data () {
        return {
            isModalPointSchemeOpen: false,
            isModalBadgeOpen: false,
            isModalCategoriesOpen: false,
        };
    },
    computed: {
        pointSchemes () {
            return this.$store.getters["data/pointSchemes"];
        },
        badges () {
            return this.$store.getters["data/badges"];
        },
        categories () {
            return this.$store.getters["data/categories"];
        },
        needs () {
            return this.$store.getters["data/needs"];
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.list {
    margin-left: -8.5px;
    margin-right: -8.5px;
}

.title {
    top: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 99;
}

.subheading {
    top: 55.25px;
    background-color: rgba(255, 255, 255, 1);
    z-index: 99;
}
</style>
