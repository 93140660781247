<template>
    <div>
        <div class="category-card card shadow-sm text-decoration-none text-dark rounded-lg h-100">
            <div class="card-body p-3">
                <h3 class="card-title font-weight-bolder">
                    🏷️ {{ name }}
                </h3>
                <div>
                    <label class="font-weight-light mb-1">
                        Members
                    </label>
                    <div class="d-flex align-items-center flex-nowrap">
                        <p class="mb-0"
                           v-if="clients.length === 0">
                            No member yet...
                        </p>
                        <template v-else>
                            <b-avatar-group class="flex-grow-1 mr-2"
                                            size="45px">
                                <b-avatar class="shadow-sm"
                                          v-for="{ id, name } in clients"
                                          v-bind:key="id"
                                          v-bind:text="`${ name[0] }${ name[1] }`"
                                          v-b-tooltip.hover.bottom="name">
                                </b-avatar>
                            </b-avatar-group>
                            <p class="mb-0"
                               v-if="clients.length > 5">
                                + {{ clients.length - 5 }} others
                            </p>
                        </template>
                    </div>
                </div>
            </div>
            <div class="text-right px-2 pb-2">
                <button class="btn btn-sm btn-warning rounded-pill border-0 shadow-sm mr-1"
                        v-on:click="isModalCategoryOpen = true">
                    ✍️ Update
                </button>
                <button class="btn btn-sm btn-danger rounded-pill border-0 shadow-sm"
                        v-on:click="deleteCategory">
                    🗑️ Delete
                </button>
            </div>
        </div>

        <modal-category is-editing
                        v-bind:category="category"
                        v-model="isModalCategoryOpen">
        </modal-category>
    </div>
</template>

<script>
import {
    VBTooltip,
    BAvatar, BAvatarGroup,
} from "bootstrap-vue";
import ModalCategory from "./ModalCategory";

export default {
    name: "CategoryCard",
    directives: {
        "b-tooltip": VBTooltip,
    },
    components: {
        ModalCategory,
        BAvatarGroup,
        BAvatar,
    },
    props: {
        category: {
            type: Object,
        },
    },
    data() {
        return {
            isModalCategoryOpen: false,
        };
    },
    computed: {
        clients () {
            return this.$store.getters["data/getClientsByCategory"](this.category.id);
        },
        name () {
            return this.category?.name ?? "";
        },
        description () {
            return this.category?.description ?? "";
        },
    },
    methods: {
        async deleteCategory () {
            await this.$store.commit("data/deleteCategory", this.category.id);
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
