<template>
    <b-modal size="md"
             centered
             hide-header
             hide-footer
             content-class="shadow-sm border-0 rounded-lg"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <div class="title position-sticky d-flex justify-content-between align-items-center mb-4">
            <h1 class="font-weight-bolder">
                🏆 Leaderboard
            </h1>
            <div>
                <button class="btn btn-lg btn-outline-dark border-0 mr-1"
                        v-on:click.prevent.stop="hideModal">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                </button>
            </div>
        </div>

        <ul class="list-group shadow-sm">
            <li class="list-group-item d-flex"
                v-for="({ id, name, pointBalance }, index) in clients"
                v-bind:key="id">
                <div style="flex: 1">
                    {{ index === 0 ? "🥇" : index === 1 ? "🥈" : "🥉" }}
                </div>
                <div style="flex: 4;">
                    {{ name }}
                </div>
                <div class="font-weight-bold text-right"
                     style="flex: 2">
                    {{ pointBalance }}
                </div>
            </li>
        </ul>
    </b-modal>
</template>

<script>
import {
    BModal,
} from "bootstrap-vue";

export default {
    name: "ModalLeaderboard",
    components: {
        BModal,
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },
        "clients": {
            type: Array,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        clientsByBalance () {
            return this.clients
                .slice(0)
                .sort(
                    (a, b) => b.pointBalance - a.pointBalance
                );
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
