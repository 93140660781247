<template>
    <div>
        <div class="point-scheme-card card shadow-sm text-decoration-none text-dark rounded-lg h-100">
            <div class="card-body p-3">
                <h3 class="card-title font-weight-bolder">
                    💰 {{ name }}
                </h3>
                <p class="mb-0"
                   v-if="description">
                    {{ description }}
                </p>
                <div>
                    <label class="font-weight-light mb-1">
                        Participants
                    </label>
                    <div class="d-flex align-items-center flex-nowrap">
                        <p class="mb-0"
                           v-if="clients.length === 0">
                            No participant yet...
                        </p>
                        <template v-else>
                            <b-avatar-group class="flex-grow-1 mr-2"
                                            size="45px">
                                <b-avatar class="shadow-sm"
                                          v-for="{ id, name } in clients"
                                          v-bind:key="id"
                                          v-bind:text="`${ name[0] }${ name[1] }`"
                                          v-b-tooltip.hover.bottom="name">
                                </b-avatar>
                            </b-avatar-group>
                            <p class="mb-0"
                               v-if="clients.length > 5">
                                + {{ clients.length - 5 }} others
                            </p>
                        </template>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between px-2 pb-2">
                <button class="btn btn-sm btn-primary text-nowrap rounded-pill border-0 shadow-sm mr-1"
                        v-on:click="isModalLeaderboardOpen = true">
                    🏆 Leaderboard
                </button>
                <div class="text-nowrap">
                    <button class="btn btn-sm btn-warning rounded-pill border-0 shadow-sm mr-1"
                            v-on:click="isModalPointSchemeOpen = true">
                        ✍️ Update
                    </button>
                    <button class="btn btn-sm btn-danger rounded-pill border-0 shadow-sm"
                            v-on:click="deleteScheme">
                        🗑️ Delete
                    </button>
                </div>
            </div>
        </div>

        <modal-leaderboard v-bind:clients="clients"
                           v-model="isModalLeaderboardOpen">
        </modal-leaderboard>
        <modal-point-scheme is-editing
                            v-bind:scheme="scheme"
                            v-model="isModalPointSchemeOpen">
        </modal-point-scheme>
    </div>
</template>

<script>
import {
    VBTooltip,
    BAvatar, BAvatarGroup,
} from "bootstrap-vue";
import ModalLeaderboard from "./PointSchemeCard/ModalLeaderboard";
import ModalPointScheme from "./ModalPointScheme";

export default {
    name: "PointSchemeCard",
    directives: {
        "b-tooltip": VBTooltip,
    },
    components: {
        ModalPointScheme,
        ModalLeaderboard,
        BAvatarGroup,
        BAvatar,
    },
    props: {
        scheme: {
            type: Object,
        },
    },
    data () {
        return {
            isModalPointSchemeOpen: false,
            isModalLeaderboardOpen: false,
        };
    },
    computed: {
        clients () {
            return this.$store.getters["data/getClientsByPointScheme"](this.scheme.id);
        },
        name () {
            return this.scheme?.name ?? "";
        },
        description () {
            return this.scheme?.description ?? "";
        },
    },
    methods: {
        async deleteScheme () {
            await this.$store.commit("data/deletePointScheme", this.scheme.id);
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
