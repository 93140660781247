<template>
    <div>
        <div class="badge-card card shadow-sm text-decoration-none text-dark rounded-lg h-100">
            <div class="card-body p-3 flex-grow-1">
                <h3 class="card-title font-weight-bolder">
                    🏵️ {{ name }}
                </h3>
                <div>
                    <label class="font-weight-light mb-1">
                        Awardees
                    </label>
                    <div class="d-flex align-items-center flex-nowrap">
                        <p class="mb-0"
                           v-if="clients.length === 0">
                            No awardee yet...
                        </p>
                        <template v-else>
                            <b-avatar-group class="flex-grow-1 mr-2"
                                            size="45px">
                                <b-avatar class="shadow-sm"
                                          v-for="{ id, name } in clients"
                                          v-bind:key="id"
                                          v-bind:text="`${ name[0] }${ name[1] }`"
                                          v-b-tooltip.hover.bottom="name">
                                </b-avatar>
                            </b-avatar-group>
                            <p class="mb-0"
                               v-if="clients.length > 5">
                                + {{ clients.length - 5 }} others
                            </p>
                        </template>
                    </div>
                </div>
            </div>
            <div class="text-right px-2 pb-2">
                <button class="btn btn-sm btn-warning rounded-pill border-0 shadow-sm mr-1"
                        v-on:click="isModalBadgeOpen = true">
                    ✍️ Update
                </button>
                <button class="btn btn-sm btn-danger rounded-pill border-0 shadow-sm"
                        v-on:click="deleteBadge">
                    🗑️ Delete
                </button>
            </div>
        </div>

        <modal-badge is-editing
                     v-bind:badge="badge"
                     v-model="isModalBadgeOpen">
        </modal-badge>
    </div>
</template>

<script>
import {
    VBTooltip,
    BAvatarGroup, BAvatar,
} from "bootstrap-vue";
import ModalBadge from "./ModalBadge";
export default {
    name: "BadgeCard",
    directives: {
        "b-tooltip": VBTooltip,
    },
    components: {
        ModalBadge,
        BAvatarGroup,
        BAvatar,
    },
    props: {
        badge: {
            type: Object,
        },
    },
    data () {
        return {
            isModalBadgeOpen: false,
        };
    },
    computed: {
        clients () {
            return this.$store.getters["data/getClientsByBadge"](this.badge.id);
        },
        name () {
            return this.badge?.name ?? "";
        },
        description () {
            return this.badge?.description ?? "";
        },
    },
    methods: {
        async deleteBadge () {
            await this.$store.commit("data/deleteBadge", this.badge.id);
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
