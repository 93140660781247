<template>
    <b-modal size="lg"
             centered
             hide-header
             hide-footer
             content-class="shadow-sm border-0 rounded-lg"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <h1 class="font-weight-bolder mb-4">
            {{ isEditing ? "Update" : "Create" }}  category 🏷️
        </h1>
        <form v-on:submit.prevent="save">
            <b-form-group>
                <template v-slot:label>
                    Name
                </template>
                <b-form-input class="bg-light"
                              required
                              placeholder="How do you call it? 📛"
                              v-model="name">
                </b-form-input>
            </b-form-group>

            <b-form-group>
                <template v-slot:label>
                    Description
                </template>
                <b-form-textarea class="bg-light"
                                 placeholder="Any info you wanna add?"
                                 v-model="description">
                </b-form-textarea>
            </b-form-group>

            <div class="text-right">
                <button class="btn btn-sm btn-primary px-3"
                        type="submit">
                    Confirm
                </button>
            </div>
        </form>
    </b-modal>
</template>

<script>
import {
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
} from "bootstrap-vue";

export default {
    name: "ModalCategory",
    components: {
        BModal,
        BFormGroup,
        BFormInput,
        BFormTextarea,
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },
        "isEditing": {
            type: Boolean,
            default: false,
        },
        "category": {
            type: Object,
        },
    },
    data () {
        return {
            name: "",
            description: "",
        };
    },
    computed: {

    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
        reset () {
            this.name = "";
            this.description = "";
        },
        save () {
            if (this.isEditing) {
                this.update();
                return;
            }
            this.create();
        },
        async create () {
            const { name, description, } = this;
            await this.$store.commit(
                "data/addCategory",
                {
                    name, description,
                }
            );
            this.hideModal();
            this.reset();
        },
        async update () {
            const { name, description, } = this;
            await this.$store.commit(
                "data/editCategory",
                {
                    "id": this.category.id,
                    name, description,
                }
            );
            this.hideModal();
        },
    },
    mounted () {
        if (this.category) {
            this.name = this.category.name;
            this.description = this.category.description;
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
